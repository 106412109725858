/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dispatch, SetStateAction, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';

import {
  setCardCode,
  resetCardCode,
  resetUserCredits,
  resetUserDetails,
} from 'src/redux/userSlice';
import Company from 'types/company';
import AccountTypes from 'types/account';
import { resetBasket } from 'src/redux/basketSlice';
import InfiniteLoader from '@components/infiniteLoader';
import getCookies from '@utils/getCookies';

interface accountProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  setAccountTab: Dispatch<SetStateAction<number>>;
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  userCredits: AccountTypes['credits'] | null;
  accountDetails: AccountTypes['accountDetails'] | null;
  isAccountDetailsLoading: boolean;
  handleClickReset: () => void;
  setIsRegisterModalOpen: Dispatch<SetStateAction<boolean>>;
}

function HeaderAccount({
  companyID,
  baseUrl,
  apiKey,
  setIsMenuOpen,
  setAccountTab,
  isLoggedIn,
  setIsLoggedIn,
  userCredits,
  accountDetails,
  isAccountDetailsLoading,
  handleClickReset,
  setIsRegisterModalOpen,
}: accountProps): JSX.Element {
  const dispatch = useDispatch();
  const router = useRouter();
  const emailExpression = /\S+@\S+\.\S+/;
  const [isForgotClicked, setIsForgotClicked] = useState<boolean>(false);
  const [logInState, setLoginState] = useState({
    username: '',
    password: '',
  });
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState<boolean>(false);
  const [isLogInError, setIsLogInError] = useState<boolean>(false);
  const environment = process.env.NEXT_PUBLIC_APP_ENV;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLoginState({
      ...logInState,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickLogin = (e): void => {
    e.preventDefault();
    if (
      logInState.username !== '' &&
      emailExpression.test(logInState.username) &&
      logInState.password !== ''
    ) {
      setLoginMessage('');
      setIsLogInError(false);
      setIsLoginLoading(true);
      axios({
        method: 'POST',
        url: `${baseUrl}/account/api/Auth/Login?companyID=${companyID}`,
        headers: {
          'ocp-apim-subscription-key': apiKey,
          // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        },
        data: {
          username: logInState.username,
          password: logInState.password,
        },
      })
        .then((response) => {
          document.cookie = `authToken=${response.data}; max-age=86400; path=/; ${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          document.cookie = `authEmail=${logInState.username}; max-age=86400; path=/; ${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          window.ScarabQueue.push(['setEmail', logInState.username]);
          window.ScarabQueue.push(['go']);
          // window.dataLayer.push({
          //   uid: {
          //     email: logInState.username,
          //     zipcode: '',
          //     keywords: [],
          //   },
          // });
          // localStorage.removeItem('guestBasket');
          dispatch(setCardCode({ companyID, baseUrl, apiKey, token: response.data }));
          setLoginState({
            username: '',
            password: '',
          });
          setIsLoginLoading(false);
          setIsMenuOpen(false);
          setAccountTab(0);
          document.body.style.overflow = 'auto';
          setIsLogInError(false);
        })
        .catch(() => {
          document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          document.cookie = `authEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          document.cookie = `IDLWebUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          document.cookie = `IDLBasket=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
            environment === 'production'
              ? `domain=.${
                  companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                }direct.co.uk;`
              : ''
          }`;
          // document.cookie = `IDLFeatureFlag=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=.${
          //   companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
          // }direct.co.uk;`;
          setIsLogInError(true);
          setIsLoginLoading(false);
        });
    } else {
      setLoginMessage('Please provide email address and password.');
      setIsLogInError(true);
    }
  };

  const handleClickLogout = (): void => {
    const domain = companyID === Company.Ironmongery ? 'ironmongery' : 'electrical';

    setIsLogoutLoading(true);
    axios({
      method: 'POST',
      url: `${baseUrl}/account/api/Auth/Logout`,
      headers: {
        'ocp-apim-subscription-key': apiKey,
        Authorization: getCookies('authToken'),
      },
    })
      .then(() => {
        document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
          environment === 'production' ? `domain=.${domain}direct.co.uk;` : ''
        }`;
        document.cookie = `authEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
          environment === 'production' ? `domain=.${domain}direct.co.uk;` : ''
        }`;
        document.cookie = `IDLWebUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
          environment === 'production' ? `domain=.${domain}direct.co.uk;` : ''
        }`;
        document.cookie = `IDLBasket=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
          environment === 'production' ? `domain=.${domain}direct.co.uk;` : ''
        }`;
        // document.cookie = `IDLFeatureFlag=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=.${
        //   companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
        // }direct.co.uk;`;
        localStorage.clear();
        dispatch(resetCardCode());
        dispatch(resetUserCredits());
        dispatch(resetUserDetails());
        setIsLogoutLoading(false);
        setIsMenuOpen(false);
        setAccountTab(0);
        setIsLoggedIn(false);
        dispatch(resetBasket());
        document.body.style.overflow = 'auto';
        if (router.pathname !== '/') router.push('/');
      })
      .catch(() => {
        setIsLogoutLoading(false);
      });
  };

  const handleSendResetEmail = (): void => {
    setIsLoading(true);
    if (email) {
      axios({
        method: 'POST',
        url: `${baseUrl}/account/forgottenMyPassword?emailAddress=${email}&companyID=${companyID}`,
        headers: {
          'ocp-apim-subscription-key': apiKey,
          // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        },
        data: {
          email,
        },
      })
        .then((res) => {
          const responseMessage = res?.data?.message;
          const noRecordMessage =
            'Sorry, there is no record of an account with that email address.';
          document.body.style.overflow = 'auto';
          setMessage(responseMessage);
          setIsError(responseMessage === noRecordMessage);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsError(true);
          setMessage(error?.response?.data?.message || 'Something went wrong.');
          setIsLoading(false);
        });
    } else {
      setIsError(true);
      setIsLoading(false);
      setMessage('Please provide an email address.');
    }
  };

  const handleClickRegister = () => {
    handleClickReset();
    setIsRegisterModalOpen(true);
  };

  return isLoggedIn && accountDetails !== null ? (
    <div className="flex flex-col" id="account-aside">
      <div className="block" id="aside-account-block">
        <div
          className={`flex items-center text-white ${
            userCredits?.tradeAccount ? '' : 'md:mb-7/50 lg:mb-6/25'
          }`}
        >
          <p
            data-cs-mask
            className="text-22px font-bold leading-34px m-0 md:text-20px md:leading-30px lg:text-26px lg:leading-40px"
          >
            {`${accountDetails.firstName} ${accountDetails.lastName}`}
          </p>
        </div>
        {userCredits?.tradeAccount && (
          <div className="flex flex-col mt-6/25 md:my-7/50 lg:my-6/25">
            <p className="credit__header">TradeXtra Remaining Credit</p>
            <div className="credit__main">
              <div className="credit__progress">
                <span
                  style={{
                    width: `${(userCredits?.available / userCredits?.creditLimit) * 100}%`,
                  }}
                >
                  <span className="progress__meter" />
                </span>
              </div>
              <div className="credit__amount">
                <p>
                  <span>{`£${userCredits?.available
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span>
                  {` of £${userCredits?.creditLimit
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col py-6/25 px-0 border-t-1 border-b-1 border-solid border-black-order-sum-b">
          <Link href="/account">
            <a
              title="My Account"
              className="text-white rounded-lg mb-6"
              role="button"
              tabIndex={0}
              onClick={handleClickReset}
            >
              <div
                className={`flex justify-between items-center py-2 px-0 ${
                  companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
                }`}
              >
                <p className="text-18px leading-relaxed md:text-20px">My Account</p>
                <svg
                  className="inline-block h-5 w-5 transition-all transform duration-200 align-middle overflow-visible fill-current md:h-6 md:w-6 1192px:w-3 1192px:h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
              </div>
            </a>
          </Link>
          <Link href="/account/previousorders">
            <a
              title="My Orders"
              className="text-white rounded-lg mb-6"
              role="button"
              tabIndex={0}
              onClick={handleClickReset}
            >
              <div
                className={`flex justify-between items-center py-2 px-0 ${
                  companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
                }`}
              >
                <p className="text-18px leading-relaxed md:text-20px">My Orders</p>
                <svg
                  className="inline-block h-5 w-5 transition-all transform duration-200 align-middle overflow-visible fill-current md:h-6 md:w-6 1192px:w-3 1192px:h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
              </div>
            </a>
          </Link>
          <Link href="/account/wishlist">
            <a
              title="Saved For Later"
              className="text-white rounded-lg mb-6"
              role="button"
              tabIndex={0}
              onClick={handleClickReset}
            >
              <div
                className={`flex justify-between items-center py-2 px-0 ${
                  companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
                }`}
              >
                <p className="text-18px leading-relaxed md:text-20px">Saved For Later</p>
                <svg
                  className="inline-block h-5 w-5 transition-all transform duration-200 align-middle overflow-visible fill-current md:h-6 md:w-6 1192px:w-3 1192px:h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
              </div>
            </a>
          </Link>
          <Link href="/account/favourite">
            <a
              title="My Top Products"
              className="text-white rounded-lg"
              role="button"
              tabIndex={0}
              onClick={handleClickReset}
            >
              <div
                className={`flex justify-between items-center py-2 px-0 ${
                  companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
                }`}
              >
                <p className="text-18px leading-relaxed md:text-20px">My Top Products</p>
                <svg
                  className="inline-block h-5 w-5 transition-all transform duration-200 align-middle overflow-visible fill-current md:h-6 md:w-6 1192px:w-3 1192px:h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
              </div>
            </a>
          </Link>
        </div>
        <div className="mt-6/25">
          <button
            disabled={isLogoutLoading}
            className={`cta-btn--transparent${companyID === Company.Ironmongery ? '--id' : '--ed'}`}
            onClick={handleClickLogout}
          >
            <span className="block text-center">
              {isLogoutLoading ? (
                <InfiniteLoader className="bg-transparent block w-full h-10 text-16px leading-6" />
              ) : (
                'Log Out'
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col" id="account-aside">
      <div className={isForgotClicked ? 'hidden' : 'block'} id="aside-login-block">
        {isAccountDetailsLoading ? (
          <div className="account__loader">
            <PulseLoader
              sizeUnit="px"
              size={20}
              margin="10px"
              color={companyID === Company.Ironmongery ? '#EFA847' : '#C7D402'}
              loading={true}
            />
          </div>
        ) : (
          <>
            <div className="flex items-center text-white">
              <p className="text-18px font-bold leading-relaxed m-0 md:text-20px md:leading-loose">
                Log In
              </p>
            </div>
            <form onSubmit={handleClickLogin}>
              <div className="flex flex-col border-b-1 border-solid border-black-order-sum-b py-5 px-0">
                <div className="flex flex-col mb-5">
                  <label
                    htmlFor="aside-username-login"
                    className="border-0 h-px overflow-hidden p-0 absolute w-px"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="aside-username-login"
                    name="username"
                    placeholder="Email Address"
                    className="text-black-lighter py-1 px-5 block appearance-none w-full h-10 leading-normal text-14px my-0 mx-auto border-2 border-solid border-transparent outline-none rounded-full md:py-2 md:px-5"
                    value={logInState.username}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <div
                    className="hidden mt-5 bg-black-product-notice border-1 border-solid border-black-product-notice-b rounded-md py-4 px-5"
                    id="aside-username-validation"
                  >
                    <p
                      className={`inline-block items-center text-16px mr-1 mb-1 ${
                        companyID === Company.Ironmongery ? 'text-orange' : 'text-green'
                      }`}
                    ></p>
                    <p className="text-white text-16px m-0"></p>
                  </div>
                </div>
                <div className="flex flex-col mb-5">
                  <label
                    htmlFor="aside-password"
                    className="border-0 h-px overflow-hidden p-0 absolute w-px"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="aside-password"
                    name="password"
                    placeholder="Password"
                    className="text-black-lighter py-1 px-5 block appearance-none w-full h-10 leading-normal text-14px my-0 mx-auto border-2 border-solid border-transparent outline-none rounded-full md:py-2 md:px-5"
                    value={logInState.password}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <div
                    className={`${
                      isLogInError ? 'block ' : 'hidden '
                    }mt-5 bg-black-product-notice border-1 border-solid border-black-product-notice-b rounded-md py-4 px-5`}
                    id="aside-password-validation"
                  >
                    <p
                      className={`${
                        companyID === Company.Ironmongery ? 'text-orange' : 'text-green'
                      } inline-block items-center text-16px mr-1 mb-1`}
                    >
                      Something went wrong
                    </p>
                    <p className="text-white text-16px m-0">
                      {loginMessage ? (
                        <>{loginMessage}</>
                      ) : (
                        <>
                          We were unable to log you in. Ensure both your email address and password
                          are correct. If you cannot log in, try and{' '}
                          <button
                            data-tracking="forgot"
                            type="button"
                            id="aside-login-forgot-message"
                            className="hover:underline text-16px italic inline-block text-white border-0 font-normal bg-transparent mt-5 md:mt-0 focus:outline-none"
                            onClick={() => {
                              setIsForgotClicked(true);
                            }}
                          >
                            reset your password
                          </button>
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start md:flex-row md:items-center md:justify-between">
                  <button
                    data-tracking="login"
                    type="submit"
                    id="aside-login"
                    disabled={isLoginLoading}
                    className={`cta-btn${
                      companyID === Company.Ironmongery ? '--id' : '--ed'
                    } focus:outline-none`}
                  >
                    <span className="block text-center">
                      {isLoginLoading ? (
                        <InfiniteLoader
                          className={
                            'bg-transparent block w-full h-10 text-black-lighter text-16px leading-6'
                          }
                        />
                      ) : (
                        'Log In'
                      )}
                    </span>
                  </button>
                  <button
                    data-tracking="forgot"
                    type="button"
                    id="aside-login-forgot"
                    className="hover:underline text-14px inline-block text-white border-0 font-normal bg-transparent mt-5 md:mt-0 focus:outline-none"
                    onClick={() => {
                      setIsForgotClicked(true);
                    }}
                  >
                    I&apos;ve forgotten my password
                  </button>
                </div>
              </div>
            </form>
            <div className="py-6/25">
              <div className="text-white flex items-center">
                <p>Don’t have an account?</p>
              </div>
              <a
                className={`cta-btn${
                  companyID === Company.Ironmongery ? '--id' : '--ed'
                } mt-5 bg-transparent border-1 border-solid border-white text-white ${
                  companyID === Company.Ironmongery
                    ? 'hover:text-orange hover:border-orange'
                    : 'hover:text-green hover:border-green'
                }`}
                role="button"
                tabIndex={0}
                onClick={handleClickRegister}
              >
                Register today
              </a>
            </div>
            <div className="border-t-1 border-solid border-black-order-sum-b pt-6/25">
              <h3 className="mb-5 text-lg md:text-xl">Reasons to sign up today</h3>
              <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-8">
                <div className="w-full md:w-1/2 flex flex-row md:flex-col">
                  <div className="bg-white flex px-2 md:px-5 py-1 md:py-2 rounded-xl w-3/12 md:w-full">
                    <img
                      src="https://img-cdn.manutantraders.com/image/upload/v1714642203/ironmongery/icons/in-stock.png"
                      alt="Over 18,000 products in stock"
                      className="mx-auto w-full"
                    />
                  </div>
                  <p className="ml-5 md:ml-0 md:mt-3 text-sm md:text-base text-grey-button my-auto">
                    Over 18,000 products in stock
                  </p>
                </div>
                <div className="w-full md:w-1/2 flex flex-row md:flex-col">
                  <div className="bg-white flex px-2 md:px-5 py-1 md:py-2 rounded-xl w-3/12 md:w-full">
                    <img
                      src="https://img-cdn.manutantraders.com/image/upload/v1714642203/ironmongery/icons/free-returns.png"
                      alt="Free returns"
                      className="mx-auto w-full"
                    />
                  </div>
                  <p className="ml-5 md:ml-0 md:mt-3 text-sm md:text-base text-grey-button my-auto">
                    Free returns
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:space-x-8 mt-5">
                <div className="w-full md:w-1/2 flex flex-row md:flex-col">
                  <div className="bg-white flex px-2 md:px-5 py-1 md:py-2 rounded-xl w-3/12 md:w-full">
                    <img
                      src="https://img-cdn.manutantraders.com/image/upload/v1714642205/ironmongery/icons/min-guarantee.png"
                      alt="Free delivery available"
                      className="mx-auto w-full"
                    />
                  </div>
                  <p className="ml-5 md:ml-0 md:mt-3 text-sm md:text-base text-grey-button my-auto">
                    Free delivery available
                  </p>
                </div>
                <div className="w-full md:w-1/2 flex flex-row md:flex-col">
                  <div className="bg-white flex px-2 md:px-5 py-1 md:py-2 rounded-xl w-3/12 md:w-full">
                    <img
                      src="https://img-cdn.manutantraders.com/image/upload/v1714642116/ironmongery/icons/free-delivery.png"
                      alt="Free Returns"
                      className="mx-auto w-full"
                    />
                  </div>
                  <p className="ml-5 md:ml-0 md:mt-3 text-sm md:text-base text-grey-button my-auto">
                    Five year minimum guarantee
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={!isForgotClicked ? 'hidden' : 'block'} id="aside-forgot-block">
        <div className="text-white items-center flex">
          <p className="text-18px font-bold leading-7 m-0 md:text-20px md:leading-loose">
            Forgotten Your Password?
          </p>
        </div>
        <div
          className="flex flex-col border-b-1 border-solid border-black-order-sum-b py-5 px-0 md:pt-5 md:pb-7/50"
          id="aside-forgot-form"
        >
          <div className="flex flex-col mb-5">
            <label
              htmlFor="aside-username-forgot"
              className="border-0 h-px overflow-hidden p-0 absolute w-px"
            >
              Email Address
            </label>
            <input
              type="email"
              id="aside-username-forgot"
              name="email"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              className="text-black-lighter py-1 px-5 block appearance-none w-full h-10 leading-normal text-14px my-0 mx-auto border-2 border-solid border-transparent outline-none rounded-full md:py-2 md:px-5"
              value={email}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendResetEmail();
                }
              }}
            />
            <div
              className={`${
                message ? 'block ' : 'hidden '
              } mt-5 bg-black-product-notice border-1 border-solid border-black-product-notice-b rounded-md py-4 px-5`}
              id="aside-username-forgot-validation"
            >
              {isError ? (
                <p className={`inline-block items-center text-16px mr-1 mb-1 text-error404`}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <span className="ml-2">Something went wrong.</span>
                </p>
              ) : (
                <p
                  className={`${
                    companyID === Company.Ironmongery ? 'text-orange' : 'text-green'
                  } inline-block items-center text-16px mr-1 mb-1`}
                >
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span className="ml-2">Password reset request submitted.</span>
                </p>
              )}
              <p className="text-white text-16px m-0">{message}</p>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start md:flex-row md:items-center md:justify-between">
            {message !==
              'A link to reset your password will be sent to your email address within 24 hours.' && (
              <button
                data-tracking="forgot-submit"
                type="submit"
                id="aside-forgot"
                disabled={isLoading}
                onClick={handleSendResetEmail}
                className={`cta-btn${
                  companyID === Company.Ironmongery ? '--id' : '--ed'
                } focus:outline-none`}
              >
                {isLoading ? (
                  <InfiniteLoader
                    className={
                      'bg-transparent block w-full h-10 text-black-lighter text-16px leading-6'
                    }
                  />
                ) : (
                  <span className="block text-center">Send reset email</span>
                )}
              </button>
            )}
            <button
              type="button"
              id="aside-forgot-toggle"
              className="hover:underline text-14px inline-block text-white border-0 font-normal bg-transparent mt-5 md:mt-0 focus:outline-none"
              onClick={() => {
                setIsForgotClicked(false);
                setEmail('');
                setMessage('');
              }}
            >
              Return to login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderAccount;
